/*
 * bootstrap over-ridings
 */
.form-control {
    font-size: 0.875rem;
}

/*
 * Others
 */
.box {
    margin-top: 53px !important;
}
